<template>
  <div class="space-info">
    <CForm
            ref="updateMember"
            @submit.prevent="updateSpace"
            novalidate
            class="update-space-form"
    >
      <!--  HEADER    -->
      <CreateHeader
          :loading="isButtonDisabled"
          :title="'Space'"
          :descriptions="descriptions"
          submit-title="update"/>
      <!--  CONTENT  -->
      <div class="form-content content">
        <!--  Name    -->
        <ClFormRow label="Name*" :toolTipText="descriptions.name">
          <template #content>
            <CInput
                    placeholder="Name"
                    v-model="formData.name"
                    add-input-classes="col-sm-12"
                    required
            />
          </template>
        </ClFormRow>
        <!--  Account Type    -->
        <ClFormRow label="Account Type*" :toolTipText="descriptions.accountType">
          <template #content>
            <CInput
                    placeholder="company"
                    v-model.trim="formData.accountType"
                    add-input-classes="col-sm-12"
                    required
                    invalidFeedback="Required"
                    :is-valid="nameValidate"
            />
          </template>
        </ClFormRow>
        <!--  Master Space  -->
        <ClFormRow label="Master Space" :toolTipText="descriptions.masterSpace">
          <template #content>
            <CInput
                    placeholder="Master Space"
                    v-model.trim="formData.masterSpace"
                    add-input-classes="col-sm-12"
                    invalidFeedback="Required"
                    :is-valid="nameValidate"
            />
          </template>
        </ClFormRow>
      </div>
    </CForm>
  </div>
</template>

<script>
  import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
  import ClFormRow from "@/shared/components/formComponents/ClFormRow";
  import CreateHeader from "@/shared/components/CreateHeader";
  import {space} from "@/config/descriptions/space";
  import SpacesApiZiqni from "@/generated/ziqni/api/SpacesApiZiqni";

  export default {
    name: "Space",
    components: {
      ClFormRow,
      CreateHeader,
    },
    data() {
      return {
        descriptions: {
          ...space
        },
        formData: {
          name: '',
          accountType: '',
          masterSpace: '',
        },
        nameValidate: null,
      }
    },
    computed: {
      isButtonDisabled() {
        // if (this.loading) return true;
        // return !!this.message;
        return false
      },
    },
    created() {
      this.initialize();
      this.formData.name = localStorage.getItem("zq-space-name");
    },
    methods: {
      initialize() {
        routerBreadcrumbs(this.$router.currentRoute);
      },
      updateSpace() {
        console.log('UPDATE', this.formData);
        SpacesApiZiqni.getSpacesByName(localStorage.getItem("zq-space-name")).then(res => console.log(res))
      },
    }
  }
</script>

<style lang="scss">
  .space-info {
    display: flex;
    flex-direction: column;
    height: 100%;

    .update-space-form {
      height: calc(100% - 50px);
    }

    .form-content {
      height: 100%;
      background: var(--zq-sub-bg);
      border-top: 1px solid var(--zq-content-border-color);
    }
  }
</style>